import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import Modal from "react-modal";
import { IModalOption } from "../../../shared/interfaces/modal-option.interface";
import ReactPlayer from "react-player";
import InstructionsModal from "./InstructionsModal";
import { IPlayAGameResponse } from "../../../shared/services/game/game.service";
import { IGame } from "../../../shared/interfaces/game.interface";

const HelpModal = ({
    showModal,
    onClose,
    url,
    gameAssets,
    level,
    sendMessage,
    onlyShowVideo
}: IModalOption & {
    gameAssets: IPlayAGameResponse;
    level: string;
    sendMessage?: Function;
    onlyShowVideo?: boolean
}) => {
    const [isMobile] = useState(window.outerWidth < 768);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [showErrorText, setShowErrorText] = useState(false);
    const videoTutorial = gameAssets?.game?.videoTutorial;

    const getInstructions =  (game: IGame) => {
        switch (level) {
            case 'WORD':
                return game?.wordInstructions || []
            case 'PHRASE':
                return game?.phraseInstructions || []
            case 'SENTENCE':
                return game?.sentenceInstructions || []
        
            default:
                return [];
        }
    }

    const instructions = onlyShowVideo ? [] : getInstructions(gameAssets?.game);

    const handleContactClickedClick = () => {
        window.location.href = "mailto:support@athomearticulation.com";
    };

    const showErrorMsg = () => {
        setShowErrorText(true);
        setTimeout(() => {
            setShowErrorText(false);
        }, 7000);
    }

    const cacheImages = () => {
        const promises = instructions.map( i => {
            return new Promise(function (res, rej) {
                const img = new Image()
                img.onload = function() {
                    res(img)
                  }
                  img.onerror = img.onabort = function() {
                    rej();
                  }
                img.src = i.fileSrc;
            })
        });

        Promise.all(promises).then(() => console.log('finish'));
    }
    
    useEffect(() => {
        if (onlyShowVideo) return;
        cacheImages();
    }, [])
    

    return (
        <Modal
            isOpen={showModal}
            style={{
                content: {
                    top: "35%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : "40%",
                    minWidth: isMobile ? "300px" : "600px",
                    padding: "10px 20px",
                    backgroundColor: "#DBEDF2",
                    borderRadius: "8px",
                },
            }}
        >
            {!onlyShowVideo && !showVideoModal && !showInstructions && (
                <div
                    className={
                        "flex flex-col justify-between bg-aha-light-blue"
                    }
                >
                    <div
                        className={
                            "font-bold liberation-sans text-2xl sm:text-3xl md:text-4xl text-center mt-8 text-aha-orange"
                        }
                    >
                        What do you need help with?
                    </div>
                    <div className={"absolute top-0 right-0"}>
                        <button
                            onClick={() => {
                                onClose(false);
                            }}
                        >
                            <XIcon className={"w-10 text-gray-500"} />
                        </button>
                    </div>

                    <div
                        className={"flex flex-row mt-16 mb-16 justify-between"}
                    >
                        <div className={"flex flex-col mr-8 ml-8 w-full"}>
                            <div
                                onClick={() => {
                                    if (instructions?.length) {
                                        setShowInstructions(true);
                                    } else {
                                        showErrorMsg();
                                    }
                                }}
                                className="h-48 bg-white cursor-pointer border-solid border-2 rounded-3xl relative flex flex-col justify-between hover:bg-gray-100"
                            >
                                <div></div>
                                <div className="h-24 bg-gray-300 rounded-3xl relative flex flex-col justify-center items-center ml-4 mr-4">
                                    <p
                                        className={
                                            "font-bold text-lg text-center text-aha-dark-blue"
                                        }
                                    >
                                        Game Instructions
                                    </p>
                                </div>
                                <div
                                    className={
                                        "flex w-full justify-center mb-4"
                                    }
                                >
                                    <div
                                        className={
                                            "border-solid border bg-gray-300 border-aha-dark-blue rounded-full h-4 w-4"
                                        }
                                    ></div>
                                    <div
                                        className={
                                            "mr-4 ml-4 bg-gray-300 border-solid border border-aha-dark-blue rounded-full h-4 w-4"
                                        }
                                    ></div>
                                    <div
                                        className={
                                            "bg-gray-300 border-solid border border-aha-dark-blue rounded-full h-4 w-4"
                                        }
                                    ></div>
                                </div>
                            </div>
                            {showErrorText && (
                                <p
                                    className={
                                        "liberation-sans text-center text-red-400 mt-2"
                                    }
                                >
                                    Oops! It seems there are no instructions to
                                    show. Please, contact the administrator to
                                    report it.
                                </p>
                            )}
                        </div>

                        <div className={"flex flex-col w-full ml-8 mr-8"}>
                            <div
                                className="h-48 bg-white cursor-pointer border-solid border-2 rounded-3xl flex flex-col justify-between hover:bg-gray-100"
                                onClick={() => {
                                    setShowVideoModal(true);
                                    if (sendMessage) {
                                        sendMessage("Mute", "SetMute", "true");
                                    }
                                }}
                            >
                                <p
                                    className={
                                        "mt-4 text-center liberation-sans"
                                    }
                                >
                                    {videoTutorial?.title ||
                                        "2-minute video tutorial"}
                                </p>
                                <div
                                    className={
                                        "w-full h-full flex justify-center bg-red"
                                    }
                                >
                                    <div
                                        style={{
                                            width: 0,
                                            height: 0,
                                            marginTop: 20,
                                            borderTop: "40px solid transparent",
                                            borderBottom:
                                                "40px solid transparent",
                                            borderLeft: "50px solid #F7941E",
                                        }}
                                    ></div>
                                </div>
                                <div></div>
                            </div>
                            <p
                                className={
                                    "liberation-sans text-center text-aha-dark-blue mt-2"
                                }
                            >
                                {videoTutorial?.legend ||
                                    "What if my child is saying the sound wrong?"}
                            </p>
                        </div>
                    </div>
                    <div
                        className={"flex flex-col items-center justify-center"}
                    >
                        <p
                            className={
                                "liberation-sans text-center text-aha-dark-blue mt-2"
                            }
                        >
                            Contact Customer Support:
                        </p>
                        <p
                            className={
                                "liberation-sans text-center text-aha-dark-blue hover:underline cursor-pointer"
                            }
                            onClick={handleContactClickedClick}
                        >
                            support@athomearticulation.com
                        </p>
                    </div>
                </div>
            )}

            {!onlyShowVideo && (
                <InstructionsModal
                    showModal={showInstructions}
                    onClose={() => {
                        setShowInstructions(false);
                        onClose();
                    }}
                    instructions={instructions}
                    gameTitle={gameAssets?.game?.title}
                    levelTitle={level}
                />
            )}

            {(onlyShowVideo || showVideoModal) && (
                <>
                    <div className={"flex justify-between"}>
                        <div
                            className={
                                "uppercase font-blanch-bold md:text-5xl tracking-wider text-gray-500"
                            }
                        >
                            How to use at home articulation
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    if (onlyShowVideo) {
                                        onClose(false);
                                        return;
                                    }
                                    
                                    setShowVideoModal(false);
                                    if (sendMessage) {
                                        sendMessage("Mute", "SetMute", "false");
                                    }
                                }}
                            >
                                <XIcon className={"w-10 text-gray-500"} />
                            </button>
                        </div>
                    </div>
                    <div className={"my-2"}>
                        {loadingVideo && <p>Loading..</p>}
                        <ReactPlayer
                            url={url}
                            controls
                            playing
                            width="100%"
                            height="300px"
                            onReady={() => setLoadingVideo(false)}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};

export default HelpModal;
