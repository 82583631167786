import { gql } from "@apollo/client";

export const getReadingContentsQuery = gql`
    query getReadingContents($input: GetReadingContentsInput!) {
        getReadingContents(input: $input) {
            pagingMeta {
                count
            }
            data {
		    	id
			    readingLevel
			    wordPosition
		    }
        }
    }
`;
