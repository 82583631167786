import { useContext, useEffect, useState } from "react";
import GameFeedbackModal from "./gameFeedbackModal";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../shared/context/authContext/AuthContext";

const GameFeedbackComponent = () => {
    const { state } = useContext(AuthContext);
    const [showGameFeedbackModal, setShowGameFeedbackModal] = useState(false);
    const [gameLogId, setgameLogId] = useState(null);
    const history = useHistory();
    const role = state.user?.role ?? "UNKNOWN_ROLE";

    useEffect(() => {
        if (!["PARENT", "TEACHER"].includes(role)) {
            localStorage.removeItem("gameAssets");
            history.push("/app/lets-play");
            return;
        }

        const gameAssets = JSON.parse(
            localStorage.getItem("gameAssets") || "{}"
        );
        if (gameAssets?.gameLogId) {
            setgameLogId(gameAssets.gameLogId);
            setShowGameFeedbackModal(true);
        } else {
            history.push("/app/lets-play");
        }
    }, [history, role]);
    
    return (
        <div className="flex justify-center">
            <GameFeedbackModal
                gameLogId={gameLogId}
                showModal={showGameFeedbackModal}
                onClose={() => {
                    setShowGameFeedbackModal(false);
                    try {
                        localStorage.removeItem("gameAssets");
                    } catch (error) {
                        console.log(error);
                    }
                    history.push("/app/lets-play");
                }}
            />
        </div>
    );
};

export default GameFeedbackComponent;
