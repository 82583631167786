import questionMarkImage from "../../../assets/images/game/question-mark-button.png"
import closeImage from "../../../assets/images/game/close-button.png"
import { useHistory } from "react-router-dom";

const ReadingContent = ({ data, onFinish, setShowModal }: any) => {
    const history = useHistory();
    return (
        <div>
            <div className="space-y-4">
                <div className="border-aha-green border-8 md:w-3/4 p-4 mx-auto space-y-4">
                    {data.map((content: any) => {
                        return (
                            <div className="relative" key={content.id}>
                                <div className="card_btn_container">
                                    <img className="card_btn" onClick={() => history.push("/app/lets-play")} src={closeImage} alt="Close Button" />
                                    <img className="card_btn" onClick={() => setShowModal(true)} src={questionMarkImage} alt="Question Mark Button" />
                                </div>
                                <img
                                    src={content.file}
                                    alt={content.title}
                                    className={"w-full"}
                                />
                                <div
                                    onClick={onFinish}
                                    className="test_class"
                                ></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ReadingContent;
