import apolloClient from "../../../config/apolloClient";
import { getReadingContentsQuery } from "./reading.gql";

export interface IGetReadingContentsPayload {
    args?: {
        sound: string;
        wordPosition?: string;
        readingLevel?: string;
    };
}

export const getReadingContentsRequest = async (payload: IGetReadingContentsPayload) => {
    const { data, errors } = await apolloClient.query({
        query: getReadingContentsQuery,
        fetchPolicy: "no-cache",
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getReadingContents };
};
