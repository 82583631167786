import apolloClient from "../../../config/apolloClient";
import { getFlashcardsQuery } from "./flashcards.gql";

export interface IGetFlashcardsPayload {
    args?: {
        sound: string;
        wordPosition?: string;
    };
}

export const getFlashcardsRequest = async (payload: IGetFlashcardsPayload) => {
    const { data, errors } = await apolloClient.query({
        query: getFlashcardsQuery,
        fetchPolicy: "no-cache",
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getFlashcards };
};
