import { gql } from "@apollo/client";

export const getFlashcardsQuery = gql`
    query getFlashcards($input: GetFlashcardsInput!) {
        getFlashcards(input: $input) {
            pagingMeta {
                count
            }
        }
    }
`;
